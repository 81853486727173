export const promptOptions = [
  'Rock',
  'Pop',
  'Jazz',
  'Classical',
  'Hip-hop',
  'EDM (Electronic Dance Music)',
  'Blues',
  'Country',
  'Reggae',
  'Folk',
  'Happy',
  'Sad',
  'Energetic',
  'Relaxing',
  'Melancholic',
  'Uplifting',
  'Nostalgic',
  'Romantic',
  'Aggressive',
  'Calm',
  'Piano',
  'Guitar',
  'Violin',
  'Drums',
  'Saxophone',
  'Synthesizer',
  'Bass',
  'Flute',
  'Cello',
  'Trumpet',
  'Acoustic',
  'Electric',
  'Ambient',
  'Orchestral',
  'Lo-fi',
  'Funky',
  'Groovy',
  'Epic',
  'Minimalistic',
  'Cinematic',
  'Melody',
  'Harmony',
  'Rhythm',
  'Beat',
  'Tempo',
  'Dynamics',
  'Timbre'
];

export const getRandomOptions = (options: string[], count: number) => {
  const shuffled = options.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};
