// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpotifyMusic_spotifyLogin__IH0h9 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
@media screen and (max-width: 900px) {
  .SpotifyMusic_spotifyLogin__IH0h9 {
    min-height: 200px;
  }
}

.SpotifyMusic_spotifySearchMusicContainer__5onmo {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.SpotifyMusic_spotifyLoginButton__K7cpQ {
  text-align: center;
  width: 150px;
  margin: 10px;
  background-color: #FFA17A;
  padding: 8px 10px;
  color: white;
  font-family: Montserrat, sans-serif;
  align-self: flex-end;
  border-radius: 7px;
  font-size: 20px;
  cursor: pointer;
}

.SpotifyMusic_spotifyLoginButton__K7cpQ:hover {
  background: white;
  color: #FFA17A;
}

.SpotifyMusic_errorContainer__k5mqc {
  background: red;
  color: white;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/SpotifyModal/SpotifyMusic/SpotifyMusic.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EANF;IAOI,iBAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,mCAAA;EACA,oBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AAEF;;AACA;EACE,iBAAA;EACA,cAAA;AAEF;;AACA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF","sourcesContent":[".spotifyLogin {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50%;\n\n  @media screen and (max-width: 900px) {\n    min-height: 200px;\n  }\n}\n\n.spotifySearchMusicContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: start;\n  align-items: center;\n  height: 100%;\n}\n\n.spotifyLoginButton {\n  text-align: center;\n  width: 150px;\n  margin: 10px;\n  background-color: #FFA17A;\n  padding: 8px 10px;\n  color: white; \n  font-family: Montserrat, sans-serif;\n  align-self: flex-end;\n  border-radius: 7px;\n  font-size: 20px;\n  cursor: pointer;\n}\n\n.spotifyLoginButton:hover {\n  background: white;\n  color: #FFA17A;\n}\n\n.errorContainer {\n  background: red;\n  color: white;\n  width: 100%;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spotifyLogin": `SpotifyMusic_spotifyLogin__IH0h9`,
	"spotifySearchMusicContainer": `SpotifyMusic_spotifySearchMusicContainer__5onmo`,
	"spotifyLoginButton": `SpotifyMusic_spotifyLoginButton__K7cpQ`,
	"errorContainer": `SpotifyMusic_errorContainer__k5mqc`
};
export default ___CSS_LOADER_EXPORT___;
