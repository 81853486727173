import SignInSignUp from "../SignInSignUp/SignInSignUp";
import styles from './HomeComponent.module.scss'

const HomeComponent = () => {
  return (
    <div className={styles.home}>
      <SignInSignUp title='Log in'/>
    </div>
  );
};

export default HomeComponent;