import { FC } from 'react';
import { useNavigate } from "react-router-dom";
import cn from 'classnames';
import { HOME } from '../../constants/routes';
import { useAppContext } from '../../hooks/useAppContext';
import { MENU_ITEMS } from '../Main/Main';
import styles from './NavigationMenu.module.scss';

interface NavigationMenuProps {
  isOpen: boolean;
  onClose: () => void;
  setSelectedItem: (item: any) => void;
}

const NavigationMenu: FC<NavigationMenuProps> = ({ isOpen, onClose, setSelectedItem }) => {
  const navigate = useNavigate();
  const { clearToken } = useAppContext();
  const handleMenuItemClick = (item: any) => {
    setSelectedItem(item);
    onClose(); // Close the navigation after clicking on a menu item
  };

  const handleLogout = () => {
    navigate(HOME);
    clearToken();
  };

  return (
    <div className={cn(styles.root, {
      [styles.open]: isOpen
    })} onClick={onClose}>
      <div className={styles.menuContainer} onClick={(e) => e.stopPropagation()}>
        <ul>
          {MENU_ITEMS.map((item, index) => (
            <li key={index} onClick={() => handleMenuItemClick(item)}>
              <p>{item.label}</p>
            </li>
          ))}
          <div className={styles.mobileLogoutButton} onClick={handleLogout}>
            Logout
          </div>
        </ul>
      </div>
    </div>
  );
};

export default NavigationMenu;
