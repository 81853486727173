import { useContext } from "react";
// Context
import { AppContext, AppContextType } from "../../src/context/AppContext";

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within and AppContext Provider');
  }

  return context;
}