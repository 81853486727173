// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AudioEffectsControler_audioEffectsControlerRoot__B-s5C {
  overflow-y: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.AudioEffectsControler_actionContainer__SoVuo {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
}

.AudioEffectsControler_actions__KS4fV {
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}

.AudioEffectsControler_actions__KS4fV > div {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.33% - 20px); /* Subtract the gap to prevent overflow */
  max-width: calc(33.33% - 20px); /* Ensure the max-width aligns with flex-basis */
}

.AudioEffectsControler_react-dial-knob__4fiSj .AudioEffectsControler_donut__8UM3s {
  background-image: none !important; /* Removes any unwanted background images */
}

.AudioEffectsControler_react-dial-knob__4fiSj svg {
  display: none; /* Hides any SVG that might be causing the issue */
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DJMode/AudioEffectsControler/AudioEffectsControler.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,2BAAA;AACF;;AAEA;EACE,YAAA;EAAA,cAAA;EAAA,+BAAA,EAAA,yCAAA;EACA,8BAAA,EAAA,gDAAA;AACF;;AAGA;EACE,iCAAA,EAAA,2CAAA;AAAF;;AAGA;EACE,aAAA,EAAA,kDAAA;AAAF","sourcesContent":[".audioEffectsControlerRoot {\n  overflow-y: hidden;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.actionContainer {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  height: 100%;\n}\n\n.actions {\n  background-color: white;\n  border-radius: 20px;\n  padding: 10px;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-content: space-around;\n}\n\n.actions > div {\n  flex: 1 1 calc(33.33% - 20px); /* Subtract the gap to prevent overflow */\n  max-width: calc(33.33% - 20px); /* Ensure the max-width aligns with flex-basis */\n}\n\n\n.react-dial-knob .donut {\n  background-image: none !important; /* Removes any unwanted background images */\n}\n\n.react-dial-knob svg {\n  display: none; /* Hides any SVG that might be causing the issue */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audioEffectsControlerRoot": `AudioEffectsControler_audioEffectsControlerRoot__B-s5C`,
	"actionContainer": `AudioEffectsControler_actionContainer__SoVuo`,
	"actions": `AudioEffectsControler_actions__KS4fV`,
	"react-dial-knob": `AudioEffectsControler_react-dial-knob__4fiSj`,
	"donut": `AudioEffectsControler_donut__8UM3s`
};
export default ___CSS_LOADER_EXPORT___;
