import { FC, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { API_URL } from '../../../../constants/environment';
import { useAppContext } from '../../../../hooks/useAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { showToast } from '../../../Toast/Toast';
import styles from './SpotifySearch.module.scss';

type Artist = {
  name: string;
}

interface SpotifySong {
  url: string;
  title: string;
  artists: Artist[];
  downloaded: boolean;
}

interface SelectedSpotifySong { 
  selected: boolean 
  url: string;
};

interface SpotifySearchProps {
  refreshMusicList: () => void;
}

export const SpotifySearch: FC<SpotifySearchProps> = ({ refreshMusicList }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [spotifySongs, setSpotifySongs] = useState<SpotifySong[]>([]);
  // const [selectedSpotifyUrls, setSelectedSpotifyUrls] = useState<SelectedSpotifySong[]>([]);
  const { token } = useAppContext();

  const handleSearch = async() => {
    try {
      const response = await axios.get(`${API_URL}/spotify/search?query=${encodeURIComponent(searchTerm)}`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      // Map over the response.data and set downloaded to false for each item
      const spotifySongsWithDownloadStatus = response.data.map((song: SpotifySong) => ({
        ...song,
        downloaded: false,
      }));

      setSpotifySongs(spotifySongsWithDownloadStatus);    } catch (error: any) {
      showToast({type: 'error', message: error.message});
    }
  };

  const onDownload = async(url: string) => {
    setSpotifySongs((prevSongs) => {
      return prevSongs.map((song) => {
        if (song.url === url) {
          // Update the specific song with downloaded: true
          return { ...song, downloaded: true };
        }
        return song;
      });
    });
    
    try {
      const downloadInput = {
        urls: [url],
      };
      await axios.post(`${API_URL}/spotify/download`, downloadInput, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      refreshMusicList();
      showToast({ message: 'Download finished' });
    } catch (error: any) {
      showToast({ type: 'error', message: 'Something went wrong' });
      console.error(error);
    }
  }

  // const onSelectSpotifySong = (songUrl: string) => {
  //   // Check if the song is already selected
  //   const isSelected = selectedSpotifyUrls.some((song) => song.url === songUrl);
  
  //   // If it's selected, remove it from the selectedSongs array
  //   if (isSelected) {
  //     const updatedSelectedSongs = selectedSpotifyUrls.filter((song) => song.url !== songUrl);
  //     setSelectedSpotifyUrls(updatedSelectedSongs);
  //   } else {
  //     // If it's not selected, add it to the selectedSongs array
  //     const selectedSong = spotifySongs.find((song) => song.url === songUrl);
  //     if (selectedSong) {
  //       setSelectedSpotifyUrls([...selectedSpotifyUrls, { selected: true, url: songUrl }]);
  //     }
  //   }
  // };

  const onPlay = (url: string) => {
    window.open(url, '_blank');
  }

  const combinedArtists = (artistsArray: Artist[]) => artistsArray.map((artist, index) => artist.name).join(' & ');

  return (
    <div className={styles.root}>
      <p className={styles.title}>Spotify Search</p>

      <div>
        <input
          type="text"
          placeholder="Enter spotify song name"
          className={styles.searchSpotifyInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
        />
        <button className={styles.searchButton} onClick={handleSearch}>Search</button>
      </div>

      <div className={styles.container}>
        <div className={styles.table}>
            {spotifySongs.map((song: any) => (
              <div className={styles.tableRow} key={song.url}>
                <div className={cn(styles.tableData, styles.name)}>{combinedArtists(song.artists || [])}</div>
                <div className={cn(styles.tableData, styles.name)}>{song.title}</div>
                <div className={cn(styles.tableData, styles.actions)}>
                  <FontAwesomeIcon icon={faPlay} onClick={() => onPlay(song.url)} className={styles.playButton} />
                  <FontAwesomeIcon 
                     icon={song.downloaded ? faSpinner : faDownload} 
                     onClick={() => onDownload(song.url)} 
                     className={styles.downloadButton}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};