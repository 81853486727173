import { useState } from 'react';
import UploadedMusic, { SelectedSong, Song } from './UploadedMusic/UploadedMusic';
import styles from './Dashboard.module.scss';
import Prompt from './Prompt/Prompt';
import GeneratedMusic from 'components/Dashboard/GeneratedMusic/GeneratedMusic';

const Dashboard = () => {
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const [selectedSongs, setSelectedSongs] = useState<SelectedSong[]>([]);
  const [currentSong, setCurrentSong] = useState<Song | null>(null);

  const handleSelectedSongs = (songs: SelectedSong[]) => {
    setSelectedSongs(songs);
  }
  
  const handlePlaySong = (song: Song) => {
    setCurrentSong(song);
  }

  return (
      <div className={styles.dashboard}>
        <UploadedMusic 
          refreshTrigger={refreshTrigger} 
          onSelectedSongs={handleSelectedSongs}
          onPlaySong={handlePlaySong}  
        />
        <Prompt 
          refreshTrigger={refreshTrigger} 
          selectedSongs={selectedSongs}
        />
        <GeneratedMusic 
          // refreshTrigger={refreshTrigger} 
          // onSelectedSongs={handleSelectedSongs}
          currentlyPlayedSong={currentSong}
          onPlaySong={handlePlaySong}  
        />
      </div>
  );
}

export default Dashboard;