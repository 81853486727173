import { Token } from '../context/AppContext';
// Helpers
import { isJsonString } from './textEditor';

export const TOKEN_NAME = 'token';
export const COOKIES_ACCESS_TOKEN_NAME = 'access-token';
export const COOKIES_REFRESH_TOKEN_NAME = 'refresh-token';
export const GUEST_TOKEN_NAME = 'guest-token';
export const REDIRECT = 'redirect';

const setItem = (key: string, value: string): void => {
  typeof window !== 'undefined' && localStorage.setItem(key, value);
};

const getItem = (key: string): string | null => {
  return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
};

const removeItem = (key: string): void => {
  typeof window !== 'undefined' && localStorage.removeItem(key);
};

// Token
export const setTokenToLocalStorage = (token: Token): void => {
  setItem(TOKEN_NAME, JSON.stringify(token));
};

export const getTokenFromLocalStorage = (): Token | null => {
  const token = getItem(TOKEN_NAME);

  return token && isJsonString(token) ? JSON.parse(token) : null;
};

export const clearTokenFromLocalStorage = (): void => {
  removeItem(TOKEN_NAME);
};

// Utility to check if the token is expired
export const isTokenExpired = (token: Token): boolean => {
  return token.expiresAt < Date.now();
};

// Guest Token
export const setGuestTokenToLocalStorage = (guestToken: Token): void => {
  setItem(GUEST_TOKEN_NAME, JSON.stringify(guestToken));
};

export const getGuestTokenFromLocalStorage = (): Token | null => {
  const guestToken = getItem(GUEST_TOKEN_NAME);

  return guestToken && isJsonString(guestToken) ? JSON.parse(guestToken) : null;
};

export const clearGuestTokenFromLocalStorage = (): void => {
  removeItem(GUEST_TOKEN_NAME);
};

// Redirected from
export const setRedirectToLocalStorage = (path: string): void => {
  setItem(REDIRECT, path);
};

export const getRedirectFromLocalStorage = (): string | null => {
  return getItem(REDIRECT);
};

export const clearRedirectFromLocalStorage = (): void => {
  removeItem(REDIRECT);
};
