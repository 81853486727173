// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-root {
  padding: 20px 50px;
}

.title-profile {
  text-decoration: none;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: white;
}

.profile-form {
  text-decoration: none;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":[".title-root {\n  padding: 20px 50px;\n}\n\n.title-profile {\n  text-decoration: none;\n  font-family: 'Avenir', sans-serif;\n  font-weight: 500;\n  font-size: 28px;\n  color: white;\n}\n\n.profile-form {\n  text-decoration: none;\n  font-family: 'Avenir', sans-serif;\n  font-weight: 500;\n  font-size: 22px;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
