import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlay, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import axios from 'axios';
import { API_URL } from '../../../constants/environment';
import { useAppContext } from '../../../hooks/useAppContext';
import { showToast } from '../../Toast/Toast';
import DeleteSongModal from '../DeleteSongModal/DeleteSongModal';
import SpotifyModal from '../SpotifyModal/SpotifyModal';
import styles from './UploadedMusic.module.scss';

export interface Song {
  createdAt: string;
  id: number;
  path: string;
  size: number;
  title: string;
  type: string;
  updatedAt: string;
  userId: number;
}

export interface SelectedSong extends Song {
  selected: boolean;
}

interface UploadedMusicProps {
  refreshTrigger: boolean;
  onSelectedSongs?: (selectedSongs: SelectedSong[]) => void;
  onPlaySong: (song: Song) => void;
  djMode?: boolean;
}

const UploadedMusic: FC<UploadedMusicProps> = ({ refreshTrigger, onSelectedSongs, onPlaySong, djMode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [uploadedMusic, setUploadedMusic] = useState<Song[]>([]);
  const [selectedSongs, setSelectedSongs] = useState<SelectedSong[]>([]);
  const [currentSong, setCurrentSong] = useState<Song | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [spotifyModalOpen, setSpotifyModalOpen] = useState(false);
  const { token } = useAppContext();

  const fetchUploadedSongs = async () => {
    try {
      const response = await axios.get(`${API_URL}/song`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      setUploadedMusic(response.data);
    } catch (error) {
      console.error('Error fetching uploaded songs:', error);
    }
  };

  useEffect(() => {
    // Fetch uploaded songs when refreshTrigger changes
    fetchUploadedSongs();
  }, [refreshTrigger]);

   // Effect to handle search when sortDirection changes
   useEffect(() => {
      handleSearch();
  }, [sortDirection]);

  useEffect(() => {
    if (onSelectedSongs) {
      onSelectedSongs(selectedSongs);
    }
  }, [selectedSongs]);

  const onSelectSong = (songId: number) => {
    // Check if the song is already selected
    const isSelected = selectedSongs.some((song) => song.id === songId);
  
    // If it's selected, remove it from the selectedSongs array
    if (isSelected) {
      const updatedSelectedSongs = selectedSongs.filter((song) => song.id !== songId);
      setSelectedSongs(updatedSelectedSongs);
    } else {
      // If it's not selected, add it to the selectedSongs array
      const selectedSong = uploadedMusic.find((song) => song.id === songId);
      if (selectedSong) {
        setSelectedSongs([...selectedSongs, { ...selectedSong, selected: true }]);
      }
    }
  };

  const onDeleteSong = (song: Song) => {
    setCurrentSong(song);;
    setIsDeleteModalOpen(true);
  };

  const handlePlaySong = (songId: number) => {
    const song = uploadedMusic.find((song) => song.id === songId);
    const backendUrl = API_URL;
  
    if (song) {
      const songPath = song.path;
      const songUrl = `${backendUrl}${songPath}`;
  
      onPlaySong({ ...song, path: songUrl });
    }
  };

  const handleUpload = async (e: any) => {
    const formData = new FormData();
    const songInput = document.getElementById('songInput') as HTMLInputElement;
    if (songInput.files && songInput?.files?.length > 0) {
      for (let i = 0; i < songInput.files.length; i++) {
        formData.append('songs', songInput.files[i]);
      }

      try {
        await axios.post(`${API_URL}/song/upload`, formData, {
          headers: {
            'x-access-token': token?.accessToken,
            'Content-Type': 'multipart/form-data',
          },
        });
        showToast({message: 'Uploaded successfully'});
        fetchUploadedSongs();
      } catch (error) {
        console.error('Error uploading songs:', error);
        showToast({type: 'error', message: 'Uploaded successfully'});
      }
    }
  };

  const handleSearch = async() => {
    try {
      const response = await axios.get(`${API_URL}/song?direction=${sortDirection}&query=${encodeURIComponent(searchTerm)}`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      setUploadedMusic(response.data);    
      
    } catch (error: any) {
      showToast({type: 'error', message: error.message});
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    fetchUploadedSongs();
  }

  const handleOpenSpotifyModal = () => {
    setSpotifyModalOpen(true);
  }

  const handleCloseSpotifyModal = () => {
    setSpotifyModalOpen(false);
    handleRefreshMusicList();
  }

  const handleRefreshMusicList = () => {
    setTimeout(() => {
      fetchUploadedSongs();
    }, 1000)
  }

  return (
    <div className={styles.uploadedMusic}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{djMode ? 'All Music' : 'Your Music'}</p>
        <p className={styles.subTitle}>{!djMode && '(Optional)'}</p>
      </div>

      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search"
            className={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
          <div className={styles.sortButtons}>
          <button
            className={`${styles.sortButton} ${sortDirection === 'asc' ? styles.activeSortButton : ''}`}
            onClick={() => setSortDirection('asc')}
          >
            <FontAwesomeIcon icon={faArrowUp} className={styles.actionButton}/>
          </button>
          <button
            className={`${styles.sortButton} ${sortDirection === 'desc' ? styles.activeSortButton : ''}`}
            onClick={() => setSortDirection('desc')}
          >
            <FontAwesomeIcon icon={faArrowDown} className={styles.actionButton}/>
          </button>
      </div>
        </div>
        <div className={styles.table}>
          {uploadedMusic.map((song) => (
            <div className={styles.tableRow} key={song.id}>
              <div className={cn(styles.tableData, styles.checkbox)}>
                <input
                  type="checkbox"
                  checked={selectedSongs.some((item) => item.id === song.id)}
                  onChange={() => onSelectSong(song.id)}
                  className={styles.checkboxButton}
                  disabled={djMode}
                />
              </div>
              <div className={cn(styles.tableData, styles.name)}>{song.title}</div>
              <div className={cn(styles.tableData, styles.type)}>{song.type}</div>
              <div className={cn(styles.tableData, styles.actions)}>
                <FontAwesomeIcon icon={faPlay} onClick={() => handlePlaySong(song.id)} className={styles.actionButton}/>
                {!djMode && <FontAwesomeIcon icon={faTrash} onClick={() => onDeleteSong(song)} className={styles.actionButton}/>}
              </div>
            </div>
          ))}
        </div>
        
        {!djMode && (<div className={styles.buttons}>
          <button className={styles.uploadSpotifyButton} onClick={handleOpenSpotifyModal}>
              Spotify Upload
          </button>

          <button className={styles.uploadButton}>
            <label htmlFor="songInput">
              Browse
            </label>
            <input type="file" id="songInput" multiple accept="audio/*" style={{ display: 'none' }} onChange={handleUpload} />
          </button>
        </div>
        )}

      </div>
    
      <DeleteSongModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        song={currentSong}
      />

      <SpotifyModal
        isOpen={spotifyModalOpen}
        onClose={handleCloseSpotifyModal}
        onRefreshMusicList={handleRefreshMusicList}
      />
    </div>
  );
};

export default UploadedMusic;