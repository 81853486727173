// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-wrapper {
  margin-top: 20px;
}

.toast-wrapper-mobile {
  top: 0;
  margin-top: 0;
}

.toast-container {
  border-radius: 4px;
  padding: 10px;
}

.toast-body {
  padding: 0 10px 10px 0;
}

`, "",{"version":3,"sources":["webpack://./src/components/Toast/Toast.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,MAAM;EACN,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".toast-wrapper {\n  margin-top: 20px;\n}\n\n.toast-wrapper-mobile {\n  top: 0;\n  margin-top: 0;\n}\n\n.toast-container {\n  border-radius: 4px;\n  padding: 10px;\n}\n\n.toast-body {\n  padding: 0 10px 10px 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
