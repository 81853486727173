import { useState, useEffect, FC } from 'react';
import * as Tone from 'tone';
import { Song } from "../../UploadedMusic/UploadedMusic";
import { Donut } from 'react-dial-knob';
import styles from './AudioEffectsControler.module.scss';

interface AudioEffectsControlerProps {
  songSrc?: string | null;
  onPlaySong?: (song: Song) => void;
  onPauseSong?: (song: Song) => void;
}

const AudioEffectsControler: FC<AudioEffectsControlerProps> = ({ songSrc, onPlaySong, onPauseSong }) => {
  const [distortionLevel, setDistortionLevel] = useState(0);
  const [reverbLevel, setReverbLevel] = useState(0);
  const [delayTime, setDelayTime] = useState(0);
  const [vibratoFrequency, setVibratoFrequency] = useState(0);
  const [tremoloFrequency, setTremoloFrequency] = useState(0);
  const [bitDepth, setBitDepth] = useState(0);
  const [pitchShift, setPitchShift] = useState(0);
  const [chorusRate, setChorusRate] = useState(0);
  const [gain, setGain] = useState(0);

  const [player, setPlayer] = useState<Tone.Player | null>(null);
  const [trackIndex, setTrackIndex] = useState(0);
  
  useEffect(() => {
    if (player && player.loaded) {
      player.start();
    } else {
      console.error('Audio file is not loaded yet.');
    }
  }, [onPlaySong]);

  useEffect(() => {
    if (player && player.loaded) {
      player.stop();
    } else {
      console.error('Audio file is not loaded yet.');
    }
  }, [onPauseSong]);

  useEffect(() => {
    if (songSrc) {
      const newPlayer = new Tone.Player({
        url: songSrc,
        autostart: false,
        onload: () => {
          console.log('Audio file loaded');
        }
      }).toDestination();

      setPlayer(newPlayer);

      return () => {
        if (player) {
          player.stop();
          player.dispose();
        }
        newPlayer.dispose();

      };
    }
  }, [songSrc]);

  useEffect(() => {
    if (songSrc) {
      // Dispose of the existing player before creating a new one
      if (player) {
        player.stop();
        player.dispose();
      }
  
      // Create a new player instance for the new song
      const newPlayer = new Tone.Player({
        url: songSrc,
        autostart: true,
        onload: () => {
          console.log('Audio file loaded');
        }
      }).toDestination();
  
      setPlayer(newPlayer);
  
      return () => {
        // Clean up the player when the component unmounts or song changes
        if (newPlayer) {
          newPlayer.stop();
          newPlayer.dispose();
        }
      };
    }
  }, [songSrc]); // Re-run this effect when songSrc changes
  

  useEffect(() => {
    if (player) {
      const distortion = new Tone.Distortion(distortionLevel).toDestination();
      const reverb = new Tone.Reverb(reverbLevel).toDestination();
      const delay = new Tone.FeedbackDelay(delayTime, 0.5).toDestination();
      const vibrato = new Tone.Vibrato(vibratoFrequency, 0.5).toDestination();
      const tremolo = new Tone.Tremolo(tremoloFrequency, 0.75).toDestination();
      const bitCrusher = new Tone.BitCrusher(bitDepth).toDestination();
      const pitchShiftEffect = new Tone.PitchShift(pitchShift).toDestination();
      const chorusEffect = new Tone.Chorus(chorusRate, 2.5, 0.5).toDestination();
      const gainEffect = new Tone.Gain(gain).toDestination();
      
      tremolo.start();
      chorusEffect.start();
      
      player.connect(bitCrusher);
      player.connect(tremolo);
      player.connect(vibrato);
      player.connect(distortion);
      player.connect(reverb);
      player.connect(delay);
      player.connect(pitchShiftEffect);
      player.connect(chorusEffect);
      player.connect(gainEffect);

      return () => {
        console.log('test');
        // Dispose of the effects when the player is unmounted
        bitCrusher.dispose();
        tremolo.dispose();
        vibrato.dispose();
        distortion.dispose();
        reverb.dispose();
        delay.dispose();
        pitchShiftEffect.dispose();
        chorusEffect.dispose();
        gainEffect.dispose();
      };
    }
  }, [player]);

  return (
    <div className={styles.audioEffectsControlerRoot}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>Audio Effects Controller</p>
      </div>

      <div className={styles.actionContainer}>
        <div className={styles.actions}>
          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setDistortionLevel}
              ariaLabelledBy={'distortion-label'}
              diameter={100}
              min={0}
              max={100}
              step={1}
              value={distortionLevel}
            />
            <label id="distortion-label" style={{textAlign: 'center'}}>Distortion</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setReverbLevel}
              ariaLabelledBy={'reverb-label'}
              diameter={100}
              min={1}
              max={100}
              step={1}
              value={reverbLevel}
            />
            <label id="reverb-label" style={{textAlign: 'center'}}>Reverb</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setDelayTime}
              ariaLabelledBy={'delay-label'}
              diameter={100}
              min={1}
              max={200}
              step={1}
              value={delayTime}
            />
            <label id="delay-label" style={{textAlign: 'center'}}>Delay (sec)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setTremoloFrequency}
              ariaLabelledBy={'tremolo-label'}
              diameter={100}
              min={1}
              max={100}
              step={1}
              value={tremoloFrequency}
            />
            <label id="tremolo-label" style={{textAlign: 'center'}}>Tremolo (Hz)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setVibratoFrequency}
              ariaLabelledBy={'vibrato-label'}
              diameter={100}
              min={1}
              max={100}
              step={1}
              value={vibratoFrequency}
            />
            <label id="vibrato-label" style={{textAlign: 'center'}}>Vibrato (Hz)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setBitDepth}
              ariaLabelledBy={'bitdepth-label'}
              diameter={100}
              min={1}
              max={8}
              step={1}
              value={bitDepth}
            />
            <label id="bitdepth-label" style={{textAlign: 'center'}}>BitCrusher depth (bits)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setPitchShift}
              ariaLabelledBy={'pitch-shift-label'}
              diameter={100}
              min={0}
              max={10}
              step={1}
              value={pitchShift}
            />
            <label id="pitch-shift-label" style={{textAlign: 'center'}}>Pitch Shift (semitones)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setChorusRate}
              ariaLabelledBy={'chorus-rate-label'}
              diameter={100}
              min={0}
              max={80}
              step={1}
              value={chorusRate}
            />
            <label id="chorus-rate-label" style={{textAlign: 'center'}}>Chorus Rate (Hz)</label>
          </div>

          <div style={{maxWidth: '30%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
            <Donut
              theme={{
                donutColor: '#FFA17A',
                bgrColor: '#FFD9C2',
                donutThickness: 25,
              }}
              onValueChange={setGain}
              ariaLabelledBy={'gain-label'}
              diameter={100}
              min={0}
              max={100}
              step={1}
              value={gain}
            />
            <label id="gain-label" style={{textAlign: 'center'}}>Gain (Hz)</label>
          </div>
        
        </div>

      </div>
    </div>
  );
};

export default AudioEffectsControler;
