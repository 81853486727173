import { useState, createContext, useEffect, useCallback, ReactNode } from 'react';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
// Helpers
import {
  COOKIES_ACCESS_TOKEN_NAME,
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
  clearTokenFromLocalStorage,
  getGuestTokenFromLocalStorage,
} from '../helpers/storage';
import { getCurrentTimeZoneOffset } from '../helpers/timeZone';

export type Token = {
  accessToken: string | null;
  expiresAt: number;
};

type AppContextState = {
  token: Token | null;
};

type AppContextHandlers = {
  setToken: (token: Token) => void;
  clearToken: () => void;
  setAppContext: React.Dispatch<React.SetStateAction<AppContextState>>;
};

export type AppContextType = AppContextHandlers & AppContextState;

interface AppProviderProps {
  children: ReactNode;
}


const cookieAccessTokenOptions = {
  secure: true,
  expires: dayjs()
    .add(1, 'day')
    .add(getCurrentTimeZoneOffset(), 'day')
    .toDate(),
  path: '/',
};

const initialState = {
  token: getTokenFromLocalStorage(),
  guestToken: getGuestTokenFromLocalStorage(),
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [appContext, setAppContext] = useState<AppContextState>(initialState);
  const [cookies, setCookie, removeCookie] = useCookies();

  const setCookieAccessToken = useCallback(
    (accessToken: string) => {
      setCookie(
        COOKIES_ACCESS_TOKEN_NAME,
        accessToken,
        cookieAccessTokenOptions
      );
    },
    [setCookie]
  );

  useEffect(() => {
    const token = getTokenFromLocalStorage();

    if (token?.accessToken && !appContext.token) {
      setCookieAccessToken(token.accessToken);
      setAppContext((state) => ({
        ...state,
        token,
      }));
    }
  }, [
    setCookie,
    setCookieAccessToken,
    appContext.token,
  ]);

  const setToken = (token: Token) => {
    if (token.accessToken) {
      setCookieAccessToken(token.accessToken);
    }
    setTokenToLocalStorage(token);
    setAppContext((state) => ({
      ...state,
      token,
    }));
  };

  const clearToken = () => {
    clearTokenFromLocalStorage();
    removeCookie(COOKIES_ACCESS_TOKEN_NAME, cookieAccessTokenOptions);
    setAppContext((state) => ({
      ...state,
      token: null,
    }));
  };

  return (
    <AppContext.Provider
      value={{
        ...appContext,
        setToken,
        clearToken,
        setAppContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};