import React from 'react';
import {
  toast,
  ToastContainer,
  ToastContainerProps,
  ToastOptions,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';

interface ShowToastParams extends ToastOptions {
  message?: string;
}

const defaultPosition = 'top-right';
const defaultAutoClose = 4000;
const defaultHideProgressBar = false;
const defaultCloseOnClick = true;
const defaultPauseOnHover = true;
const defaultDraggable = true;

export function showToast({
  message,
  type = 'success',
  position = defaultPosition,
  autoClose = defaultAutoClose,
  hideProgressBar = defaultHideProgressBar,
  closeOnClick = defaultCloseOnClick,
  pauseOnHover = defaultPauseOnHover,
  draggable = defaultDraggable,
  ...rest
}: ShowToastParams): void {
  (toast as any)[type](!message?.length ? 'Something went wrong' : message, {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    ...rest,
  });
}

const Toast: React.FC<ToastContainerProps> = ({
  position = defaultPosition,
  autoClose = defaultAutoClose,
  hideProgressBar = defaultHideProgressBar,
  closeOnClick = defaultCloseOnClick,
  pauseOnHover = defaultPauseOnHover,
  draggable = defaultDraggable,
  newestOnTop = true,
  rtl = false,
}) => (
  <ToastContainer
    className='toast-wrapper'
    toastClassName='toast-container'
    bodyClassName='toast-body'
    position={position}
    autoClose={autoClose}
    hideProgressBar={hideProgressBar}
    closeOnClick={closeOnClick}
    pauseOnHover={pauseOnHover}
    draggable={draggable}
    newestOnTop={newestOnTop}
    rtl={rtl}
  />
);

export default Toast;
