// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteSongModal_modal__kw1F4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  width: 35%;
  height: 35%;
  padding: 20px;
  background-color: rgba(37, 64, 104, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.DeleteSongModal_modalHeader__v9lGB {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
}

.DeleteSongModal_buttons__R04AF {
  display: flex;
  justify-content: end;
}

button {
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #FFa17A;
  cursor: pointer;
}

button:hover {
  background-color: #FFa17A;
  color: white;
}

.DeleteSongModal_closeButton__bEeES {
  border: none;
  color: #17252A;
  background: transparent;
  cursor: pointer;
  font-size: 30px;
}

.DeleteSongModal_closeButton__bEeES:hover {
  color: #FFa17A;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DeleteSongModal/DeleteSongModal.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,wCAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,cAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  overflow: auto;\n  outline: none;\n  width: 35%;\n  height: 35%;\n  padding: 20px;\n  background-color: rgba(37, 64, 104, 0.2);\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.modalHeader {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 2;\n}\n\n.buttons {\n  display: flex;\n  justify-content: end;\n}\n\nbutton {\n  margin: 10px;\n  padding: 10px;\n  background-color: white;\n  border: 1px solid #FFa17A;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #FFa17A;\n  color: white;\n}\n\n.closeButton {\n  border: none;\n  color: #17252A;\n  background: transparent;\n  cursor: pointer;\n  font-size: 30px;\n}\n\n.closeButton:hover {\n  color: #FFa17A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DeleteSongModal_modal__kw1F4`,
	"modalHeader": `DeleteSongModal_modalHeader__v9lGB`,
	"buttons": `DeleteSongModal_buttons__R04AF`,
	"closeButton": `DeleteSongModal_closeButton__bEeES`
};
export default ___CSS_LOADER_EXPORT___;
