import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import axios from 'axios';
import { useAppContext } from '../../../hooks/useAppContext';
import { API_URL } from '../../../constants/environment';
import styles from './GeneratedMusic.module.scss';
import AudioControler from 'components/Dashboard/AudioControler/AudioControler';
import { showToast } from '../../Toast/Toast';

export interface Song {
  createdAt: string;
  id: number;
  path: string;
  size: number;
  title: string;
  type: string;
  updatedAt: string;
  userId: number;
}

export interface SelectedSong extends Song {
  selected: boolean;
}

interface GeneratedMusicProps {
  onPlaySong?: (song: Song) => void;
  currentlyPlayedSong?: Song | null;
}

const GeneratedMusic: FC<GeneratedMusicProps> = ({onPlaySong, currentlyPlayedSong}) => {
  const [generatedMusic, setGeneratedMusic] = useState<Song[]>([]);
  const [currentSong, setCurrentSong] = useState<Song | null>(currentlyPlayedSong || null);
  const { token } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');

  const fetchGeneratedSongs = async () => {
    try {
      const response = await axios.get(`${API_URL}/song/generated`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      setGeneratedMusic(response.data);
    } catch (error) {
      console.error('Error fetching generated songs:', error);
    }
  };
  
  useEffect(() => {
    fetchGeneratedSongs();
  }, []);

  // Effect to handle search when sortDirection changes
  useEffect(() => {
    handleSearch();
  }, [sortDirection]);

  const handlePlaySong = (songId: number) => {
    const song = generatedMusic.find((song) => song.id === songId);
    const backendUrl = API_URL;
  
    if (song) {
      const songPath = song.path;
      const songUrl = `${backendUrl}/${songPath}`;
  
      setCurrentSong({ ...song, path: songUrl });
    }
  };

  const handleSearch = async() => {
    try {
      const response = await axios.get(`${API_URL}/song/generated?direction=${sortDirection}&query=${encodeURIComponent(searchTerm)}`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      setGeneratedMusic(response.data);    
      
    } catch (error: any) {
      showToast({type: 'error', message: error.message});
    }
  };

  const handleClearList = () => {
    setGeneratedMusic([]);
  }

  return (
    <div className={styles.generatedMusic}>
      <p className={styles.title}>Generated</p>

      <div className={styles.container}>

        <div className={styles.generatedContainer}>
            <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder="Search"
                className={styles.searchInput}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSearch();
                  }
                }}
              />
              <div className={styles.sortButtons}>
              <button
                className={`${styles.sortButton} ${sortDirection === 'asc' ? styles.activeSortButton : ''}`}
                onClick={() => setSortDirection('asc')}
                >
                <FontAwesomeIcon icon={faArrowUp} className={styles.actionButton}/>
              </button>
              <button
                className={`${styles.sortButton} ${sortDirection === 'desc' ? styles.activeSortButton : ''}`}
                onClick={() => setSortDirection('desc')}
                >
                <FontAwesomeIcon icon={faArrowDown} className={styles.actionButton}/>
              </button>
          </div>
            </div>
            <div className={styles.table}>
            {generatedMusic.map((song) => (
              <div className={styles.tableRow} key={song.id}>
                <div className={cn(styles.tableData, styles.play)}>
                  <FontAwesomeIcon icon={faPlay} onClick={() => handlePlaySong(song.id)} className={styles.actionButton}/>
                </div>
                <div className={cn(styles.tableData, styles.name)}>{song.title}</div>
                <div className={cn(styles.tableData, styles.type)}>{song.type}</div>
              </div>
            ))}
            </div>
          <div className={styles.clearButtonContainer}>
            <button className={styles.button} onClick={handleClearList}>Clear</button>
          </div>
        </div>

        <AudioControler currentSong={currentlyPlayedSong} onPlaySong={onPlaySong}/>

      </div>
    </div>
  );
};

export default GeneratedMusic;