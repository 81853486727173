import { FC } from 'react';
import Modal from 'react-modal';
import styles from './SpotifyModal.module.scss'
import SpotifyMusic from './SpotifyMusic/SpotifyMusic';

interface SpotifyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRefreshMusicList: () => void;
}

const SpotifyModal:FC<SpotifyModalProps> = ({ isOpen, onClose, onRefreshMusicList }) => {
  const handleRefreshMusicList = () => {
    onRefreshMusicList();
     onClose();
   }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel=""
      className={styles.modal}
    >
      <SpotifyMusic refreshMusicList={handleRefreshMusicList} />
    </Modal>
  );
};

export default SpotifyModal;
