import React from 'react';

import './NotFound.css';

type NotFoundProps = {
  title?: string;
};

const NotFound: React.FC<NotFoundProps> = ({ title = 'Page not found!' }) => {
  return (
    <>
    <h2 className='title'>{title}</h2>
    <a className='button-redirect' href='/dashboard'>Dashboard</a>
    </>
  )
  
  
};

export default NotFound;
