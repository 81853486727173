import { useSpotifyContext } from '../../context/SpotifyContext';
import './Profile.css';

export const Profile = () => {

  const { spotifyData } = useSpotifyContext();

  return (
    <div className='title-root'>
      <p className='title-profile'>Profile</p>
      
      <div className='profile-form'>
        <p>Name: {spotifyData?.user_information?.display_name ?? ''}</p>
        <p>Email: {spotifyData?.user_information?.email ?? ''}</p>
      </div>
    </div>
  )
}
