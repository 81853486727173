import { useEffect, useRef, useState } from 'react';
import UploadedMusic, { Song } from '../UploadedMusic/UploadedMusic';
import styles from './DJMode.module.scss';
import AudioEffectsControler from './AudioEffectsControler/AudioEffectsControler';
import { useAppContext } from "../../../hooks/useAppContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import WaveSurfer from 'wavesurfer.js';
import axios from 'axios';
import { API_URL } from "../../../constants/environment";

const DJMode = () => {
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState<Song | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { token } = useAppContext();
  const [title, setTitle] = useState<string>('');
  const [allMusic, setAllMusic] = useState<Song[]>([]);
  const waveSurferRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    const fetchUploadedSongs = async () => {
      try {
        const response = await axios.get(`${API_URL}/song`, {
          headers: { 'x-access-token': token?.accessToken },
        });
        setAllMusic(response.data);
      } catch (error) {
        console.error('Error fetching uploaded songs:', error);
      }
    };
    fetchUploadedSongs();
  }, [token?.accessToken]);

  useEffect(() => {
    if (currentSong) {
      setTitle(currentSong?.title);

      if (!waveSurferRef.current) {
        waveSurferRef.current = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#d9dcff',
          progressColor: '#4353ff',
          cursorColor: '#4353ff',
          height: 150, // Adjust height as needed
          barWidth: 2, // Width of the bars for histogram
          barHeight: 0.5, // Height of the bars
        });
      }

      waveSurferRef.current.load(currentSong.path);
    }
  }, [currentSong]);

  const onPlaySong = (song: Song) => {
    setCurrentSong(song);
    setIsPlaying(true);
    waveSurferRef.current?.play();
  };

  const onPauseSong = () => {
    setIsPlaying(false);
    waveSurferRef.current?.pause();
  };

  const handlePlay = () => {
    if (currentSong) {
      onPlaySong(currentSong);
    }
  };

  const handlePause = () => {
    if (currentSong) {
      onPauseSong();
    }
  };

  return (
    <div className={styles.djMode}>
      <div className={styles.djModeContainer}>
        <div className={styles.uploadedMusic}>
          <UploadedMusic      
            refreshTrigger={refreshTrigger} 
            onPlaySong={onPlaySong} 
            djMode 
          />
        </div>
        <AudioEffectsControler songSrc={currentSong?.path} onPlaySong={onPlaySong} onPauseSong={onPauseSong} />
      </div>
      <div className={styles.audioPlayer}>
        <div id="waveform" className={styles.waveform}></div>
        <div className={styles.audioPlayerButtons}>
          <p className={styles.songTitle}>{title}</p>
          {currentSong && <button onClick={isPlaying ? handlePause : handlePlay}>
            <FontAwesomeIcon icon={isPlaying ? faPauseCircle : faPlayCircle} className={styles.icon}/>
          </button>}
        </div>
      </div>
    </div>
  );
};

export default DJMode;
