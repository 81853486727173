import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Context
import { AppProvider } from './context/AppContext';
import { SpotifyProvider } from './context/SpotifyContext';
// Constants
import { DASHBOARD, GENERATED_MUSIC, HOME } from './constants/routes';
// Components
import Dashboard from './components/Main/Main';
import HomeComponent from './components/HomeComponent/HomeComponent';
import Toast from './components/Toast/Toast';
import GeneratedMusic from './components/Dashboard/GeneratedMusic/GeneratedMusic';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import NotFound from './components/NotFound/NotFound';
// Styles
import styles from './App.module.css';

const App = () => {
  return (
      <div className={styles.body}>
        <AppProvider>
          <SpotifyProvider>
          <BrowserRouter>
              <Routes>
                <Route element={<ProtectedRoutes />}/>
                <Route path={HOME} element={<HomeComponent />} />
                <Route path={DASHBOARD} element={<Dashboard />} />
                <Route path={GENERATED_MUSIC} element={<GeneratedMusic />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
          </BrowserRouter>
          </SpotifyProvider>
        </AppProvider>
        <Toast/>
      </div>
  );
}

export default App;
