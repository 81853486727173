// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationMenu_root__9-Ze1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3AAFA9;
  z-index: 999;
  display: none;
}

.NavigationMenu_root__9-Ze1.NavigationMenu_open__9Mr69 {
  display: block;
}

.NavigationMenu_menuContainer__9I1hW {
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0px;
}

li {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 20px;
}

li:hover {
  background-color: #FFA17A;
  border-radius: 20px;
}

p {
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 40px;
  align-content: center;
}

.NavigationMenu_mobileLogoutButton__v4Hhq {
  color: white;
  background-color: #FFA17A;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  height: 60px;
  align-content: center;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/NavigationMenu/NavigationMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;AACF","sourcesContent":[".root {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: #3AAFA9;\n  z-index: 999;\n  display: none;\n}\n\n.root.open {\n  display: block;\n}\n\n.menuContainer {\n  align-items: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n}\n\nul {\n  list-style: none;\n  padding: 0px;\n}\n\nli {\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  margin-bottom: 10px;\n  cursor: pointer;\n  padding: 0 20px;\n}\n\nli:hover {\n  background-color: #FFA17A;\n  border-radius: 20px;\n}\n\np {\n  font-family: Montserrat, sans-serif;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 30px;\n  margin-bottom: 40px;\n  align-content: center;\n}\n\n.mobileLogoutButton {\n  color: white;\n  background-color: #FFA17A;\n  text-align: center;\n  font-family: Montserrat, sans-serif;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 30px;\n  height: 60px;\n  align-content: center;\n  border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `NavigationMenu_root__9-Ze1`,
	"open": `NavigationMenu_open__9Mr69`,
	"menuContainer": `NavigationMenu_menuContainer__9I1hW`,
	"mobileLogoutButton": `NavigationMenu_mobileLogoutButton__v4Hhq`
};
export default ___CSS_LOADER_EXPORT___;
