import { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { API_URL } from '../../../constants/environment';
import { useAppContext } from '../../../hooks/useAppContext';
import { showToast } from '../../Toast/Toast';
import styles from './Prompt.module.scss';
import { getRandomOptions, promptOptions } from './constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

export interface Song {
  createdAt: string;
  id: number;
  path: string;
  size: number;
  title: string;
  type: string;
  updatedAt: string;
  userId: number;
}

export interface SelectedSong extends Song {
  selected: boolean;
}

interface PromptProps {
  refreshTrigger: boolean;
  selectedSongs: SelectedSong[];
}

const Prompt: FC<PromptProps> = ({ refreshTrigger, selectedSongs }) => {
  const { token } = useAppContext();
  const [prompt, setPrompt] = useState<string>('');
  const [duration, setDuration] = useState<number>(10);
  const [randomOptions, setRandomOptions] = useState(promptOptions.slice(0, 10));
  
  useEffect(() => {
    const options = getRandomOptions(promptOptions, 10);
    setRandomOptions(options);
  }, []);

  const handleSongGenerate = async () => {
    const songIds = (selectedSongs.map(song => song.id));
    try {
      await axios.post(`${API_URL}/song/generate`, {
        body: {
          prompt,
          song_ids: songIds,
          duration,
        }
      }, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      showToast({message: 'Generating new song. It will appear in your "Generated" section in a few minutes.'});
    } catch (error) {
      console.error('Error uploading songs:', error);
      showToast({type: 'error', message: 'Something went wrong, please try again'});
    }
  }

  const handleRefresh = () => {
    const options = getRandomOptions(promptOptions, 10);
    setRandomOptions(options);
  };

  const handleAddNewPrompt = (option: string) => {
    setPrompt(`${prompt} ${option}`);
  }

  const isDisabled = !prompt.length || !selectedSongs.length;

  return (
    <div className={styles.prompt}>
      <p className={styles.title}>Prompt</p>

      <div className={styles.container}>

        <div className={styles.inputContainer}>
          <div className={styles.promptInput}>
            <textarea
              placeholder="Enter prompt for song generation"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              rows={10}
            />
          </div>
          <div className={styles.generateButtonContainer}>
            <input
              type="number"
              placeholder="Enter song duration (in seconds)"
              className={cn(styles.button, styles.promptDuration)}
              min={1}
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
            />
            <button className={styles.button} onClick={handleSongGenerate} disabled={isDisabled}>Generate</button>
          </div>
        </div>

        <div className={styles.promptOptions}>
          <button className={cn(styles.option, styles.refreshButton)} onClick={handleRefresh}>
            Refresh
            <FontAwesomeIcon icon={faRefresh} className={styles.refreshIcon}/>
          </button>
          {randomOptions.map((option, index) => (
            <button className={styles.option} key={index} onClick={() => handleAddNewPrompt(option)}>
              {option}
            </button>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Prompt;