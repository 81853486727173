import { Fragment, SetStateAction, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import logo from '../../images/logos/logo.png';
import logoText from '../../images/logos/text.png'
import cn from 'classnames';
// Routes
import { HOME } from '../../constants/routes';
// Components
import Dashboard from '../Dashboard/Dashboard';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import GeneratedMusic from '../Dashboard/GeneratedMusic/GeneratedMusic';
import { Profile } from '../Profile/Profile';
import Icon from 'ui/Icon/Icon';
import DJMode from 'components/Dashboard/DJMode/DJMode';
// Hooks
import { useAuth } from '../../hooks/useAuth';
// Styles
import styles from './Main.module.scss';

export const MENU_ITEMS = [
  {
    label: 'Generate music',
    component: <Dashboard/>,
  },
  {
    label: 'DJ Mode',
    component: <DJMode />
  },
  {
    label: 'Profile',
    component: <Profile />,
  },
  {
    label: 'Generated',
    component: <GeneratedMusic />
  },
];

const Main = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();
  const [selectedItem, setSelectedItem] = useState(MENU_ITEMS[0]);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDjMode, setIsDjMode] = useState(false);

  const toggleMode = () => {
    setIsDjMode(!isDjMode);
  };

  useEffect(() => {
    if (isDjMode) {
      setSelectedItem(MENU_ITEMS[1]);
    } else {
      setSelectedItem(MENU_ITEMS[0]);
    }
  }, [toggleMode])

  const handleMenuSelect = (item: SetStateAction<{ label: string; component: JSX.Element; }>) => {
    if (!isAuthenticated) {
      navigate(HOME);
    } else {
      setSelectedItem(item);
    }
  };

  const handleMobileSelectItem = (item: SetStateAction<{ label: string; component: JSX.Element; }>) => {
    if (!isAuthenticated) {
      navigate(HOME);
    } else {
      setSelectedItem(item);
      setMobileMenuOpen(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header>
        <div className={styles.menu}>
          <button className={styles.menuButton} onClick={toggleMobileMenu}>
            <Icon name="menu-logo" className={styles.menuIcon} />
          </button>
          <p className={styles.title}>{selectedItem.label}</p>
        </div>

        <div className={styles.modeButtons}>
          <button 
            className={cn({
              [styles.activeButton]: !isDjMode
            })} 
            onClick={toggleMode}>
              Classic
          </button>
            
          <div className={styles.modeButtonsLine}/>
            
          <button 
            className={cn({
              [styles.activeButton]: isDjMode
            })} 
            onClick={toggleMode}>
              DJ Mode
          </button>
        </div>

        <div className={styles.logo}>
          <img src={logo} alt="Logo" className={styles.logoImage} />
          <img src={logoText} alt="Logo-text" className={styles.logoText} />
        </div>
      </header>
      <Fragment>{selectedItem.component}</Fragment>
      <NavigationMenu isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} setSelectedItem={handleMobileSelectItem} />
    </div>
  );
};

export default Main;
