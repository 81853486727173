import { FC, useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import { API_URL } from "../../../constants/environment";
import { useAppContext } from "../../../hooks/useAppContext";
import { Song } from "../UploadedMusic/UploadedMusic";
import styles from "./AudioControler.module.scss";

interface AudioControlerProps {
  currentSong?: Song | null;
  onPlaySong?: (song: Song) => void;
}

const AudioControler:FC<AudioControlerProps> = ({ currentSong, onPlaySong }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [uploadedMusic, setUploadedMusic] = useState<Song[]>([]);
  const { token } = useAppContext();
  const [songSrc, setSongSrc] = useState<string>(uploadedMusic[trackIndex]?.path);
  const [title, setTitle] = useState<string>(uploadedMusic[trackIndex]?.title);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  useEffect(() => {
    const fetchUploadedSongs = async () => {
      try {
        const response = await axios.get(`${API_URL}/song`, {
          headers: {
            'x-access-token': token?.accessToken,
          },
        });
        setUploadedMusic(response.data);
      } catch (error) {
        console.error('Error fetching uploaded songs:', error);
      }
    };

    fetchUploadedSongs();
  
  }, [token?.accessToken]);

  useEffect(() => {
    if (currentSong) {
      setSongSrc(currentSong?.path); 
      setTitle(currentSong?.title);
    }
  }, [currentSong])

  useEffect(() => {
    if (currentSong) {
      setSongSrc(currentSong?.path); 
      setTitle(currentSong?.title);
      setAutoPlay(true);
    }
  }, [onPlaySong])

  useEffect(() => {
    setSongSrc(`${API_URL}${uploadedMusic[trackIndex]?.path}`);
    setTitle(uploadedMusic[trackIndex]?.title);
  }, [trackIndex, uploadedMusic])

  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? uploadedMusic.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < uploadedMusic.length - 1 ? currentTrack + 1 : 0
    );
  };

  return (
      <AudioPlayer
        className={styles.audioControlerRoot}
        autoPlay={false}
        src={`${songSrc}`}
        showSkipControls={true}
        showJumpControls={false}
        header={title ? title : ' '}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        autoPlayAfterSrcChange={autoPlay}
      />
  );
}

export default AudioControler;
