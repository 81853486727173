import { FC, useEffect, useState } from 'react';
import { API_URL } from '../../../../constants/environment';
import axios from 'axios';
import { useAppContext } from '../../../../hooks/useAppContext';
import { SpotifySearch } from '../SpotifySearch/SpotifySearch';
import { useSpotifyContext } from '../../../../context/SpotifyContext';
// Styles
import styles from './SpotifyMusic.module.scss';

interface SpotifyMusicProps {
  refreshMusicList: () => void;
}

const SpotifyMusic:FC<SpotifyMusicProps> = ({refreshMusicList}) => {
  const [authorizeURL, setAuthorizeURL] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { token } = useAppContext();
  const { spotifyData, updateSpotifyData } = useSpotifyContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSpotifyData = async () => {
    if (token?.accessToken) {
      try {
        const response = await axios.get(`${API_URL}/spotify/get_me`, {
          headers: {
            'x-access-token': token.accessToken,
          },
        });
        updateSpotifyData(response.data);
      } catch (error: any) {
          console.log(error.message)
      }
    }
  };

  useEffect(() => {
    fetchSpotifyData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!spotifyData && authorizeURL) {
      const popup = window.open(authorizeURL, 'target: _blank', 'width=500,height=600');

      const checkPopup = setInterval(() => {
        if (!popup || popup.closed) {
          clearInterval(checkPopup);
          // The user has closed the popup or completed the login
          !spotifyData && fetchSpotifyData();
        }
      }, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(checkPopup);
    }
  }, [authorizeURL]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_URL}/spotify/signin`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });

      // Store the authorization URL in state
      setAuthorizeURL(response.data.authorizeURL);
    } catch ({ response }) {
      setErrorMessage('Something went wrong, please try again');
    }
  };

  if (!spotifyData) {
    return (
      <div className={styles.spotifyLogin}>
        <button type="submit" className={styles.spotifyLoginButton} onClick={(e) => handleSubmit(e)}>
          Login to Spotify
        </button>
        {errorMessage && (
          <div className={styles.errorContainer}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.spotifySearchMusicContainer}>
      <SpotifySearch refreshMusicList={refreshMusicList}/>
    </div>
  );
};

export default SpotifyMusic;