import { FC, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from '../../constants/routes';
import { API_URL } from '../../constants/environment';
import text from '../../images/logos/text.png'
import logo from '../../images/logos/logo.png'
// Hooks
import { useAppContext } from '../../hooks/useAppContext';
// Styles
import styles from './SignInSignUp.module.scss';

interface SignInSignUpProps {
  title: string;
}

const SignInSignUp: FC<SignInSignUpProps> = ({title}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { setToken, token } = useAppContext();
  const [formTitle, setFormTitle] = useState(title);

  const isDisabled = !username.length || !password.length;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/auth/signin`, {
        username,
        password,
      });

      if (response.status === 200) {
        const data = response.data;
        const { accessToken } = data;
        const expiresAt = Date.now() + 24 * 3600 * 1000; // Token expires in 1 day

        setToken({ accessToken, expiresAt })
        navigate(DASHBOARD);
      } else {
        setErrorMessage('Invalid username or password');
      }
    } catch ({response}) {
      setErrorMessage('Invalid username or password');
    }
  };

  const handleOnRegistration = () => {
    setFormTitle('Registration');
  }

  const handleOnLogin = () => {
    setFormTitle('Login');
  }

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
        <img src={text} alt="Text" className={styles.logoText}/>
      </div>
      <p className={styles.pageTitle}>{formTitle}</p>
      <form className={styles.loginForm}>
        <input
          type="text"
          placeholder="Enter username"
          className={styles.formInput}
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <input
          type="password"
          placeholder="Enter password"
          className={styles.formInput}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <div className={styles.options}>
          <button 
            className={cn(styles.selectedButtonOption, {
              [styles.selectedButtonOptionActive]: title === 'Registration'
            })} 
            onClick={handleOnRegistration}
            disabled
          >
            Registration
          </button>
          <button 
            className={cn(styles.selectedButtonOption, {
              [styles.selectedButtonOptionActive]: title === 'Log in'
            })} 
            onClick={handleOnLogin}
            disabled
          >
            Login
          </button>
        </div>
        <button 
          type="submit" 
          className={styles.loginButton} 
          disabled={isDisabled} 
          onClick={(e) => handleSubmit(e)}
        >
          Login in to your account
        </button>
      </form>
      {errorMessage && (
        <div className={styles.errorContainer}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default SignInSignUp;