// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_title__RUbrd {
  text-decoration: none;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  padding: 0 4px;
  font-size: 24px;
  margin: 12px;
  color: white;
}

.Dashboard_button-generate__AA4Dx {
  background-color: rgba(37, 64, 104, 0.4);
  border-radius: 15px 0 !important;
  width: 25%;
  margin: 10px;
  padding: 10px;
  align-self: flex-end;
  border: 0;
  border-radius: 3px;
  font-family: "Avenir", sans-serif;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.Dashboard_button-generate__AA4Dx:hover {
  background: rgba(37, 64, 104, 0.4);
  opacity: 0.5;
}

.Dashboard_button-generate__AA4Dx:disabled {
  background: rgba(37, 64, 104, 0.4);
  opacity: 0.5;
  cursor: not-allowed;
}

/* new */
.Dashboard_dashboard__VDa\\+f {
  width: 95vw;
  height: 85vh;
  padding: 0 30px;
  gap: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .Dashboard_dashboard__VDa\\+f {
    flex-direction: column;
    height: 100%;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,iCAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,wCAAA;EACA,gCAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,SAAA;EACA,kBAAA;EACA,iCAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,kCAAA;EACA,YAAA;AACF;;AAEA;EACE,kCAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA,QAAA;AACA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EATF;IAUI,sBAAA;IACA,YAAA;IACA,mBAAA;EAEF;AACF","sourcesContent":[".title {\n  text-decoration: none;\n  font-family: 'Avenir', sans-serif;\n  font-weight: 500;\n  padding: 0 4px;\n  font-size: 24px;\n  margin: 12px;\n  color: white;\n}\n\n.button-generate {\n  background-color: rgba(37, 64, 104, 0.4);\n  border-radius: 15px 0 !important;\n  width: 25%;\n  margin: 10px;\n  padding: 10px;\n  align-self: flex-end;\n  border: 0;\n  border-radius: 3px;\n  font-family: 'Avenir', sans-serif;\n  color: white;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.button-generate:hover {\n  background: rgba(37, 64, 104, 0.4);\n  opacity: .5;\n}\n\n.button-generate:disabled {\n  background: rgba(37, 64, 104, 0.4);\n  opacity: .5;\n  cursor: not-allowed;\n}\n\n/* new */\n.dashboard {\n  width: 95vw;\n  height: 85vh;\n  padding: 0 30px;\n  gap:30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  @media screen and (max-width: 900px) {\n    flex-direction: column;\n    height: 100%;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Dashboard_title__RUbrd`,
	"button-generate": `Dashboard_button-generate__AA4Dx`,
	"dashboard": `Dashboard_dashboard__VDa+f`
};
export default ___CSS_LOADER_EXPORT___;
