// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AudioControler_audioControlerRoot__b3phX {
  bottom: 0;
  background-color: white;
  border-radius: 20px;
  height: 148px;
}

.AudioControler_audioControlerRoot__b3phX button {
  color: #FFA17A;
}

.AudioControler_audioControlerRoot__b3phX button:hover {
  background-color: transparent;
  color: #3AAFA9;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/AudioControler/AudioControler.module.scss"],"names":[],"mappings":"AAAA;EACG,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACH;;AAEA;EACG,cAAA;AACH;;AAEA;EACG,6BAAA;EACA,cAAA;AACH","sourcesContent":[".audioControlerRoot {\n   bottom: 0;\n   background-color: white;\n   border-radius: 20px;\n   height: 148px;\n}\n\n.audioControlerRoot button {\n   color: #FFA17A;\n}\n\n.audioControlerRoot button:hover {\n   background-color: transparent;\n   color: #3AAFA9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audioControlerRoot": `AudioControler_audioControlerRoot__b3phX`
};
export default ___CSS_LOADER_EXPORT___;
