// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  margin: 20px;
  color: 'grey';
  text-align: center;
  font-size: 28px;
  line-height: 20px;
}

.button-redirect {
  background-color: rgba(37, 64, 104, 0.4);
  border-radius: 15px 0 !important;
  width: 25%;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  align-items: center;
  border: 0;
  border-radius: 3px;
  font-family: 'Avenir', sans-serif;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.button-redirect:hover {
  background: rgba(37, 64, 104, 0.4);
  opacity: .5;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,gCAAgC;EAChC,UAAU;EACV,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,iCAAiC;EACjC,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,WAAW;AACb","sourcesContent":[".title {\n  margin: 20px;\n  color: 'grey';\n  text-align: center;\n  font-size: 28px;\n  line-height: 20px;\n}\n\n.button-redirect {\n  background-color: rgba(37, 64, 104, 0.4);\n  border-radius: 15px 0 !important;\n  width: 25%;\n  margin: 10px;\n  padding: 10px;\n  text-decoration: none;\n  text-align: center;\n  align-items: center;\n  border: 0;\n  border-radius: 3px;\n  font-family: 'Avenir', sans-serif;\n  color: white;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.button-redirect:hover {\n  background: rgba(37, 64, 104, 0.4);\n  opacity: .5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
