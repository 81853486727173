import React from 'react';
import cn from 'classnames';
// Icons
import CircleCheckmark from '../../images/icons/circle-checkmark.svg';
import Eye from '../../images/icons/eye.svg';
import EyeBlocked from '../../images/icons/eye-blocked.svg';
import MenuLogo from '../../images/icons/menu-logo.svg';
// Styles
import styles from './Icon.module.scss';

const ICON_NAMES = {
  'circle-checkmark': CircleCheckmark,
  eye: Eye,
  'eye-blocked': EyeBlocked,
  'menu-logo': MenuLogo
};

export type IconNames = keyof typeof ICON_NAMES;

type IconProps = React.ComponentPropsWithoutRef<'svg'> & {
  name: IconNames;
  withoutFill?: boolean;
};

const Icon = ({ name, className, withoutFill, ...rest }: IconProps) => {
  const SvgTag = ICON_NAMES[name] as 'svg';

  return (
    <SvgTag
      className={cn(
        styles.root,
        {
          [styles.withoutFill]: withoutFill,
        },
        className
      )}
      data-icon-name={name}
      aria-hidden="true"
      role="presentation"
      focusable="false"
      {...rest}
    />
  );
};

export default Icon;
