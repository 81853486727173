import { Navigate, Outlet } from 'react-router-dom';
import { HOME } from '../../constants/routes';
import { useAuth } from '../../hooks/useAuth';

const ProtectedRoutes = () => {
  const isAuthenticated = useAuth();

  return isAuthenticated ? (
    <Outlet/>
  ) : (
    <Navigate to={HOME} />
  );
};

export default ProtectedRoutes;