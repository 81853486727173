// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_body__PuluK {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (min-width: 900px) {
    height: 100vh;
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB;IACE,aAAa;IACb,cAAc;EAChB;AACF","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  \n  @media screen and (min-width: 900px) {\n    height: 100vh;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `App_body__PuluK`
};
export default ___CSS_LOADER_EXPORT___;
