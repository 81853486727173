// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icon_root__ApF6P {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  flex-shrink: 0;
  -webkit-user-select: none;
          user-select: none;
}
.Icon_root__ApF6P.Icon_withoutFill__6CMjw {
  fill: none;
}`, "",{"version":3,"sources":["webpack://./src/ui/Icon/Icon.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,UAAA;AADJ","sourcesContent":["@import \"styles/scss/_base\";\n\n.root {\n  fill: currentColor;\n  width: 1em;\n  height: 1em;\n  display: inline-block;\n  font-size: 1.5rem;\n  flex-shrink: 0;\n  user-select: none;\n\n  &.withoutFill {\n    fill: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Icon_root__ApF6P`,
	"withoutFill": `Icon_withoutFill__6CMjw`
};
export default ___CSS_LOADER_EXPORT___;
