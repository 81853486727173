import { FC } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constants/environment';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Song } from '../UploadedMusic/UploadedMusic';
import { useAppContext } from '../../../hooks/useAppContext';
import { showToast } from '../../Toast/Toast';
import styles from './DeleteSongModal.module.scss'

interface DeleteSongModalProps {
  isOpen: boolean;
  onClose: () => void;
  song: Song | null;
}

const DeleteSongModal:FC<DeleteSongModalProps> = ({ isOpen, onClose, song }) => {
  const { token } = useAppContext();
  const handleDelete = async() => {
    try {
      const response = await axios.delete(`${API_URL}/song/${song?.id}`, {
        headers: {
          'x-access-token': token?.accessToken,
        },
      });
      showToast({message: response.data.message});
      onClose();
    } catch (error: any) {
      showToast({type: 'error', message: error.message});
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel=""
      className={styles.modal}
    >
      <div className={styles.modalHeader}>
        <p>{`Are you sure you want to remove song ${song?.title}?`}</p>
        <FontAwesomeIcon icon={faClose} onClick={onClose} className={styles.closeButton}/>
      </div>
      <div className={styles.buttons}>
        <button onClick={onClose}>No</button>
        <button onClick={handleDelete}>Yes</button>
      </div>
    </Modal>
  );
};

export default DeleteSongModal;
