import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface SpotifyContextProps {
  children: ReactNode;
}

interface SpotifyContextValue {
  spotifyData: any | null;
  updateSpotifyData: (data: any) => void;
}

const SpotifyContext = createContext<SpotifyContextValue>({
  spotifyData: null,
  updateSpotifyData: () => {},
});
export const SpotifyProvider: FC<SpotifyContextProps> = ({ children }) => {
  const [spotifyData, setSpotifyData] = useState(null);

  const updateSpotifyData = (data: any) => {
    setSpotifyData(data);
  };

  return (
    <SpotifyContext.Provider value={{ spotifyData, updateSpotifyData }}>
      {children}
    </SpotifyContext.Provider>
  );
};

export const useSpotifyContext = () => {
  const context = useContext(SpotifyContext);
  if (!context) {
    throw new Error('useSpotifyContext must be used within a SpotifyProvider');
  }
  return context;
};
